@mixin shadow-own {
    border-radius: 6px !important;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05) !important;
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12) !important;
}

/**
 * General styles
 */
body {
    font-size: 1rem;
    font-family: 'Montserrat-Light', Arial, sans-serif;

    strong, .fw-bold {
        font-family: 'Montserrat-Regular', Arial, sans-serif;
        font-weight: normal !important;
    }

    .shadow-own {
        @include shadow-own;
    }

    .shadow-flag {
        border-radius: 3px;
        //border-color: rgba(0, 0, 0, 0.176);
        //border-width: 1px;
        //border-style: solid;
        box-shadow: 0 3px 10px rgba(0,0,0,.08), 0 0 3px rgba(0,0,0,.05);
        transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    }

    .shadow-text {
        text-shadow: 2px 2px 5px #000;
    }

    .shadow-svg {
        filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, .4));
    }

    .shadow-svg-blue {
        filter: drop-shadow(1px 1px 1px rgb(24, 13, 175, .4));
    }

    .text-30 {
        width: 50%;
        hyphens: auto;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
    }

    .text-70 {
        width: 50%;
    }

    .modal-dialog.birthday-modal {
        .modal-content {
            .modal-dialog {
                margin: 0 !important;

                .modal-body {
                    &.first {
                        border-bottom-width: 1px;
                        border-bottom-style: solid;
                        border-bottom-color: rgb(220, 220, 220);
                    }

                    ul {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .modal-dialog.custom-modal {
        margin: 0 auto;
        max-width: 100%;
        max-height: calc(100vh - 40px);
        padding: 20px 5px 20px 20px;
        display: flex;
        flex-direction: column;

        .modal-content {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 40px);

            .modal-dialog {
                margin: 0;
                max-width: 100%;

                .modal-content {
                    .modal-header {
                        background-color: rgb(230, 230, 230);

                        .modal-title {
                            margin-bottom: 0;
                            margin-top: 10px;
                        }
                    }

                    .modal-body {
                        background-color: rgb(240, 240, 240);

                        &.first {
                            border-bottom-width: 1px;
                            border-bottom-style: solid;
                            border-bottom-color: rgb(220, 220, 220);
                        }

                        &.scrollable {
                            overflow-y: auto;
                            background-color: #1e1e1e;
                        }
                    }

                    .modal-footer {
                        background-color: rgb(230, 230, 230);
                    }
                }
            }
        }
    }

    .card-header {
        .collapse-open, .collapse-close {
            float: right;
        }

        .collapse-open {
            display: inline-block;
        }
        .collapse-close {
            display: none;
        }

        &.collapsed {
            .collapse-open {
                display: none;
            }
            .collapse-close {
                display: inline-block;
            }

            border-bottom-width: 0 !important;
        }
    }

    .card-body {
        table {
            td {
                background-color: transparent;
                padding: 1rem;
            }
        }
    }

    table.location-detail {
        tr {
            td {
                button {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    .link-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: inline;
        margin: 0;
        padding: 0;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }

    .compass-collapsible {
        display: none;
    }

    .compass-area {
        display: none;
    }

    .compass {
        background: #222;
        border: 5px solid #444;
        border-radius: 40px;
        display: none;
        height: 200px;
        margin: 20px auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 200px;

        .arrow {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4ODUuMzg3IiBoZWlnaHQ9Ijg4NS4zODUiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzFDNzg5NCIgc3Ryb2tlLXdpZHRoPSI1LjYyOCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNNDQ2LjExNCAzODYuMzE0VjQ5OC40Ii8+PHBhdGggZmlsbD0iIzFDNzg5NCIgZD0ibTQwMy42OTYgNDQwLjU5NCA0Mi40MTgtMzEzLjM4NyA0Mi40MTcgMzEzLjM4N3oiLz48cGF0aCBmaWxsPSIjMUM3ODk0IiBkPSJtNDAzLjY5NyA0NDAuMzU3IDQyLjQxNyAzMTcuNjU4IDQyLjQxNi0zMTcuNjU4eiIvPjxjaXJjbGUgZmlsbD0iIzFDNzg5NCIgY3g9IjQ0Ni4xMTUiIGN5PSI0NDQuMTEiIHI9IjEyLjUwMSIvPjxjaXJjbGUgZmlsbD0iIzFDNzg5NCIgY3g9IjQ0Ni4xMTQiIGN5PSI0NDQuMTEiIHI9IjkiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiNCRTE2MjIiIHN0cm9rZS13aWR0aD0iNS42MjgiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZD0ibTQ0Mi4xMDYgMzg2LjMxNC0uMDAxIDExMi4wODYiLz48cGF0aCBmaWxsPSIjQkUxNjIyIiBkPSJtMzk5LjY4OCA0NDAuNTk0IDQyLjQxOC0zMTMuMzg2IDQyLjQxNiAzMTMuMzg2eiIvPjxwYXRoIGZpbGw9IiNGNkY2RjYiIGQ9Im0zOTkuNjg4IDQ0MC4zNTYgNDIuNDE3IDMxNy42NTggNDIuNDE3LTMxNy42NTZ6Ii8+PGNpcmNsZSBmaWxsPSIjMUM3ODk0IiBjeD0iNDQyLjEwNiIgY3k9IjQ0NC4xMTEiIHI9IjEyLjUwMSIvPjxjaXJjbGUgZmlsbD0iIzQwOEFBNyIgY3g9IjQ0Mi4xMDYiIGN5PSI0NDQuMTExIiByPSI5Ii8+PC9zdmc+);
            background-size: 100%;
            height: 160px;
            left: 15px;
            position: absolute;
            top: 15px;
            width: 160px;
            z-index: 1000;
        }

        .disc {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4ODUuMzg2IiBoZWlnaHQ9Ijg4NS4zODUiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgZmlsbD0iIzQwOGFhNyIgY3g9IjQ0Mi42OTMiIGN5PSI0NDIuNjkzIiByPSI0MDguOTUzIiBzdHlsZT0ic3Ryb2tlLXdpZHRoOjEuMDU2OTIiLz48Y2lyY2xlIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzFjNzg5NCIgc3Ryb2tlLXdpZHRoPSIzNTcuMDM1IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IjI4LjU3MzUsIDI3LjU4ODIiIGN4PSI0NDIuNjUxIiBjeT0iNDQyLjcxNCIgcj0iMjE0LjYxMyIvPjxwYXRoIGZpbGw9IiMxQzc4OTQiIGQ9Im01MDAuMTQ1IDM4NS4yNDMgMTA1LjA0NyA1Ny40NDgtMTA1LjA0NSA1Ny40NTQtNTcuNDU1IDEwNS4wNDktNTcuNDUtMTA1LjA0OC0xMDUuMDQ5LTU3LjQ1MiAxMDUuMDQ4LTU3LjQ1NyA1Ny40NTEtMTA1LjA0NnoiLz48cGF0aCBmaWxsPSIjNDA4QUE3IiBkPSJtNDQyLjY5NCAzNjEuNDQ1IDExNC45MDMtMzMuNjU4LTMzLjY1MyAxMTQuOTA1IDMzLjY1NSAxMTQuOTA2LTExNC45MDYtMzMuNjU2LTExNC45MDMgMzMuNjU2IDMzLjY1Mi0xMTQuOTA4LTMzLjY1NS0xMTQuOTAzeiIvPjxwYXRoIGZpbGw9IiM0MDhBQTciIGQ9Im0zOTguNTAxIDM5OC41IDQ0LjE5MS04MC44MDcgNDQuMTk2IDgwLjgwNSA4MC44MDYgNDQuMTk1LTgwLjgwNiA0NC4xOTMtNDQuMTk0IDgwLjgwNy00NC4xOTctODAuODA3LTgwLjgwNS00NC4xOTN6Ii8+PHBhdGggZmlsbD0iIzFDNzg5NCIgZD0ibTQ0Mi42OTQgMzgwLjE5NSA4OC4zODctMjUuODkxLTI1Ljg4NyA4OC4zODggMjUuODg4IDg4LjM5LTg4LjM4OC0yNS44OS04OC4zODggMjUuODkgMjUuODg2LTg4LjM5MS0yNS44ODgtODguMzg3eiIvPjx0ZXh0IGZpbGw9IiNGMUYyQzQiIGZvbnQtZmFtaWx5PSInQ2VudHVyeSciIGZvbnQtc2l6ZT0iNjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg1Ljc2NCA0NTkuODc3KSIgc3R5bGU9ImZpbGw6IzAwMCI+VzwvdGV4dD48dGV4dCBmaWxsPSIjRjFGMkM0IiBmb250LWZhbWlseT0iJ0NlbnR1cnknIiBmb250LXNpemU9IjYyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MTcuNDMgNzgwLjYxKSIgc3R5bGU9ImZpbGw6IzAwMCI+UzwvdGV4dD48dGV4dCBmaWxsPSIjRjFGMkM0IiBmb250LWZhbWlseT0iJ0NlbnR1cnknIiBmb250LXNpemU9IjYyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3NDkuMDk2IDQ1OS44NzcpIiBzdHlsZT0iZmlsbDojMDAwIj5FPC90ZXh0Pjx0ZXh0IGZpbGw9IiNGMUYyQzQiIGZvbnQtZmFtaWx5PSInQ2VudHVyeSciIGZvbnQtc2l6ZT0iNjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxNy40MyAxMzkuMTQ1KSIgc3R5bGU9ImZpbGw6IzAwMCI+TjwvdGV4dD48L3N2Zz4=);
            background-size: 100%;
            height: 180px;
            left: 5px;
            position: absolute;
            top: 5px;
            width: 180px;
            z-index: 200;
        }

        &.compass-direction {
            border: 1px solid #444;
            border-radius: 10px;
            height: 40px;
            margin: 3px 0;
            width: 40px;

            .arrow {
                height: 32px;
                left: 3px;
                top: 3px;
                width: 32px;
            }
        }
    }

    .search-form {
        //

        .search-group-shadow {
            .input-group {
                &.responsive-group {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;

                    .form-control {
                        width: 100%;
                        margin-bottom: 10px;
                        @include shadow-own;
                    }

                    .link-button-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        .button-own-position {
                            background-color: #f1f0fe;
                            @include shadow-own;

                            &.button-own-position-search {
                                //border-top-left-radius: 6px;
                                //border-bottom-left-radius: 6px;
                                //margin-left: -6px;
                                z-index: 100;
                            }

                            &:hover {
                                background-color: #180daf;
                            }
                        }

                        .button-search {
                            @include shadow-own;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        .search-form {
            .search-group-shadow {
                @include shadow-own;

                .input-group {
                    &.responsive-group {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .form-control {
                            flex-grow: 1;
                            margin-bottom: 0;
                            width: auto;
                            border-bottom-right-radius: 0 !important;
                            border-top-right-radius: 0 !important;
                        }

                        .link-button-wrapper {
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0;

                            .button-own-position {
                                border-radius: 0 !important;
                            }

                            .button-search {
                                border-bottom-left-radius: 0 !important;
                                border-top-left-radius: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .card {
        .card-footer {
            .own-position-request {
                a {
                    margin-top: 3px !important;
                    margin-bottom: 3px !important;
                    height: 40px;
                    width: 40px;

                    svg {
                        margin-left: -2px;
                    }
                }
            }
        }
    }




    hr {
        border-color: #dee2e6;
        opacity: 1;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &.no-decoration {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .next-places {
        .container {
            .row {
                .col {
                    &.col-compass {
                        display: none;
                        width: auto;
                        flex: 0 0 auto;
                        padding-right: 0 !important;
                    }

                    &.col-content {
                        flex-grow: 1;
                        padding-right: 0 !important;
                    }

                    &.col-coordinate {
                        width: 100%;
                        flex: 0 0 100%;

                        a {
                            line-height: .875em;
                            text-decoration: none;
                            width: 100px;
                        }
                    }

                    padding-top: 0 !important;
                }
            }

            border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);

            &:last-child {
                border-bottom: none;
            }
        }
    }

    @media (min-width: 768px) {
        .text-30 {
            width: 30%;
            hyphens: auto;
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
        }

        .text-70 {
            width: 70%;
        }

        .next-places {
            .container {
                .row {
                    .col {
                        &.col-coordinate {
                            width: auto;
                            flex: 0 0 auto;

                            a {
                                line-height: .875em;
                                text-decoration: none;
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }

    table.table {
        &.table-last-line {
            tbody {
                tr {
                    td.column-compass {
                        width: 0;
                        padding: 0;
                    }

                    td.column-value {
                        text-align: right;
                        a {
                            line-height: .875em;
                            text-decoration: none;
                            width: 100px;
                        }
                    }
                }

                tr:last-child > td {
                    border-bottom: 0;
                }
            }
        }
    }

    h3 {
        font-size: calc(1.3rem + 0.6vw);
    }

    h4 {
        font-size: calc(1.275rem + 0.3vw);
    }

    .without-hover {
        background-color: rgb(230, 230, 230);
        border-color: rgb(148, 157, 165);
        color: rgb(148, 157, 165);

        &:hover, &:active, &:focus, &:target {
            background-color: rgb(230, 230, 230);
            border-color: rgb(148, 157, 165);
            color: rgb(148, 157, 165);
            text-decoration: none;
            cursor: default;
        }
    }

    .form-floating input,
    .form-floating textarea {
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }
    .form-floating input:focus,
    .form-floating textarea:focus {
        box-shadow: none;
    }
    .form-floating label {
        color: #6c757d;
    }

    ul {
        li {
            font-family: 'Montserrat-Light', Arial, sans-serif;

            strong, .fw-bold {
                font-family: 'Montserrat-Bold', Arial, sans-serif;
                font-weight: normal !important;
            }
        }
    }

    .card.card-hover {
        border-radius: 4px;
        background: #fff;
        box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
        transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
        cursor: pointer;

        &:hover{
            transform: scale(1.02);
            box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
        }
    }

    .one-line {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

/**
 * Main navigation styles
 */
#mainNav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    border-bottom: 1px solid #dee2e6;
    background-color: #fff;
    font-family: "Montserrat-Bold", Arial, sans-serif;

    .navbar-brand {
        font-weight: 800;
    }

    .navbar-toggler {
        font-size: 0.75rem;
        font-weight: 800;
        padding: 0.75rem;
        text-transform: uppercase;
    }

    .navbar-nav > li.nav-item > a.nav-link {
        font-size: 0.75rem;
        font-weight: 800;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
    }
}

@media (min-width: 992px) {
    #mainNav {
        border-bottom: 1px solid transparent;
        background: transparent;

        .navbar-brand {
            color: #fff;
        }
    }
    #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
        color: rgba(255, 255, 255, 0.8);
    }
    #mainNav .navbar-nav > li.nav-item > a.nav-link {
        color: #fff;
    }
    #mainNav .navbar-nav > li.nav-item > a.nav-link:focus, #mainNav .navbar-nav > li.nav-item > a.nav-link:hover {
        color: rgba(255, 255, 255, 0.8);
    }

    #mainNav {
        transition: background-color 0.2s;
        /* Force Hardware Acceleration in WebKit */
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    #mainNav.is-fixed {
        /* when the user scrolls down, we hide the header right above the viewport */
        position: fixed;
        top: -83px;
        transition: transform 0.2s;
        border-bottom: 1px solid white;
        background-color: rgba(255, 255, 255, 0.9);
    }
    #mainNav.is-fixed .navbar-brand {
        color: #212529;
    }
    #mainNav.is-fixed .navbar-brand:focus, #mainNav.is-fixed .navbar-brand:hover {
        color: #0085A1;
    }
    #mainNav.is-fixed .navbar-nav > li.nav-item > a {
        color: #212529;
    }
    #mainNav.is-fixed .navbar-nav > li.nav-item > a:focus, #mainNav.is-fixed .navbar-nav > li.nav-item > a:hover {
        color: #0085A1;
    }
    #mainNav.is-visible {
        /* if the user changes the scrolling direction, we show the header */
        transform: translate3d(0, 100%, 0);
    }
}

/**
 * Header styles.
 */
header.header {
    position: relative;
    margin-bottom: 3rem;
    padding-top: calc(8rem + 57px);
    padding-bottom: 8rem;
    background-size: cover;
    background: #6c757d no-repeat scroll center center;
}
header.header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.5;
}
header.header .page-heading,
header.header .post-heading,
header.header .site-heading {
    color: #fff;
}
header.header .page-heading,
header.header .site-heading {
    text-align: center;
}
header.header .page-heading h1,
header.header .site-heading h1 {
    font-size: 3rem;
}
header.header .page-heading .subheading,
header.header .site-heading .subheading {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
    display: block;
    margin-top: 0.625rem;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header.header .post-heading h1 {
    font-size: 2.25rem;
}
header.header .post-heading .meta,
header.header .post-heading .subheading {
    line-height: 1.1;
    display: block;
}
header.header .post-heading .subheading {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.75rem 0 2rem;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header.header .post-heading .meta {
    font-size: 1.25rem;
    font-weight: 300;
    font-style: italic;
    font-family: "Lora", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header.header .post-heading .meta a {
    color: #fff;
}
@media (min-width: 992px) {
    header.header {
        padding-top: 12.5rem;
        padding-bottom: 12.5rem;
    }
    header.header .page-heading h1,
    header.header .site-heading h1 {
        font-size: 5rem;
    }
    header.header .post-heading h1 {
        font-size: 3.5rem;
    }
    header.header .post-heading .subheading {
        font-size: 1.875rem;
    }
}

header.header.header-image {
    padding-top: calc(3rem + 57px);
    padding-bottom: 3rem;
}

header.header.header-image .site-heading h1 {
    font-size: 2rem;
}

/**
 * Footer styles.
 */
footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: 'Montserrat-Light', Arial, sans-serif;
}

/**
 * Page calendars styles.
 */
.calendars {
    .card {
        width: 100%;
    }
}
@media (min-width: 992px) {
    .calendars {
    }
}

/**
 * Page calendar styles.
 */
.calendar {
    .card {
        width: 100%;
    }
}
@media (min-width: 992px) {
    .calendar {

    }
}

/**
 * Page page styles.
 */
.page {
    p {
        font-family: 'Montserrat-Light', Arial, sans-serif;
    }
}

/**
 * Page about styles.
 */
.about {

}

/**
 * Some responsive texts.
 */
.text-responsive {
    font-size: 0.875em;
}
.text-minimized {
    padding-left: 0 !important;
}
.button-minimized {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
    .text-responsive {
        font-size: 1em;
    }
    .text-minimized {
        &.px-3 {
            padding-left: 1rem !important;
        }
    }
    .button-minimized {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .text-responsive {
        font-size: 1em;
    }
    .text-minimized {
        &.px-3 {
            padding-left: 1rem !important;
        }
    }
    .button-minimized {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .text-responsive {
        font-size: 1em;
    }
    .text-minimized {
        &.px-3 {
            padding-left: 1rem !important;
        }
    }
    .button-minimized {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .text-responsive {
        font-size: 1em;
    }
    .text-minimized {
        &.px-3 {
            padding-left: 1rem !important;
        }
    }
    .button-minimized {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

