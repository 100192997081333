/* 0. Include all */
//@import "~bootstrap/scss/bootstrap";

// or

/* 1. Include functions first (so you can manipulate colors, SVGs, calc, etc) */
@import "~bootstrap/scss/functions";

/* 2. Include any default variable overrides here */
$body-bg: #fff;
$primary: #180daf;
$enable-negative-margins: true;

/* 3. Include remainder of required Bootstrap stylesheets */
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

/* 4. Include any default map overrides here */

/* 5. Include remainder of required parts */
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

/* 6. Optionally include any other parts as needed */
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/utilities";

/* 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss` */
@import "~bootstrap/scss/utilities/api";
