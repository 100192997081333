@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./fonts/Montserrat-SemiBold.eot');
    src: url('./fonts/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/Montserrat-SemiBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./fonts/Montserrat-Regular.eot');
    src: url('./fonts/Montserrat-Regular.woff') format('woff'),
    url('./fonts/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/Montserrat-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./fonts/Montserrat-Medium.eot');
    src: url('./fonts/Montserrat-Medium.woff') format('woff'),
    url('./fonts/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/Montserrat-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./fonts/Montserrat-Bold.eot');
    src: url('./fonts/Montserrat-Bold.woff') format('woff'),
    url('./fonts/Montserrat-Bold.ttf') format('truetype'),
    url('./fonts/Montserrat-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('./fonts/Montserrat-ExtraBold.eot');
    src: url('./fonts/Montserrat-ExtraBold.woff') format('woff'),
    url('./fonts/Montserrat-ExtraBold.ttf') format('truetype'),
    url('./fonts/Montserrat-ExtraBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('./fonts/Montserrat-Light.eot');
    src: url('./fonts/Montserrat-Light.woff') format('woff'),
    url('./fonts/Montserrat-Light.ttf') format('truetype'),
    url('./fonts/Montserrat-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'Lora-Regular';
    src: url('./fonts/Lora-Regular.eot');
    src: url('./fonts/Lora-Regular.woff') format('woff'),
    url('./fonts/Lora-Regular.ttf') format('truetype'),
    url('./fonts/Lora-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lora-Bold';
    src: url('./fonts/Lora-Bold.eot');
    src: url('./fonts/Lora-Bold.woff') format('woff'),
    url('./fonts/Lora-Bold.ttf') format('truetype'),
    url('./fonts/Lora-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lora-Italic';
    src: url('./fonts/Lora-Italic.eot');
    src: url('./fonts/Lora-Italic.woff') format('woff'),
    url('./fonts/Lora-Italic.ttf') format('truetype'),
    url('./fonts/Lora-Italic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'Fontello';
    src: url('./fonts/Fontello.eot?46579446');
    src: url('./fonts/Fontello.eot?46579446#iefix') format('embedded-opentype'),
    url('./fonts/Fontello.woff2?46579446') format('woff2'),
    url('./fonts/Fontello.woff?46579446') format('woff'),
    url('./fonts/Fontello.ttf?46579446') format('truetype'),
    url('./fonts/Fontello.svg?46579446#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
